@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .popup-full-screen{
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: none;
        /* -webkit-backdrop-filter: blur(30px); */
    }
  }

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .popup-full-screen{
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        /* background: rgb(153, 173, 172,0.9); */
    }

    .full-screen{
        filter: blur(30px) !important;
    }
}

.full-screen{
    filter: blur(15px)
}

.popup-full-screen .popup{
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    z-index: 100;
    padding: 1rem;
}
