.template-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h2{ margin: 0 0 0.9375rem 0; font-weight: 500;}

p{ margin: 0; }

.template-card-buttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.template-card-buttons a{
    text-decoration: none;
}



@media only screen and (min-width: 375px) {
    .template-card{
        min-width: 235px;
    }
}

@media only screen and (min-width: 670px) {
    .template-card-buttons{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

@media only screen and (min-width: 1150px) {
    .template-card-buttons{
        flex-direction: column;
        gap: 40px;
    }

    .template-card{
        max-width: 280px;
    }
}