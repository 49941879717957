.n4mp-input-identifier-input{
    /*v1 - background: #00322d 0% 0% no-repeat padding-box; */
    background: #077b75 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: none;
    /* -webkit-backdrop-filter: blur(30px); */
    text-align: left;
    /*v1 - color: #FFFFFF80; */
    color: white;
    border: 1px solid transparent ;
    /* width: 100%;
    max-width: 500px; */
    padding: 15px;

}

/* .n4mp-input-identifier-input:after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #00322d;
    opacity: 0.05;
    border-radius: 15px;

    z-index: -4;
} */



.n4mp-input-identifier-input:hover, .n4mp-input-identifier-input:focus, .n4mp-input-identifier-input:active{
    /*v1 -  border: 1px solid #02B4AB; */
    border: 1px solid white;
    opacity: 1;
    outline: none;
    color: #FFFFFF;
}

.n4mp-input-info-input{
    /*v1 - background: #00322d 0% 0% no-repeat padding-box; */
    background: #077b75 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    /* -webkit-backdrop-filter: blur(30px); */
    text-align: left;
    font: normal normal medium 16px/21px Exo;
    letter-spacing: 0px;
    /*v1 - color: #FFFFFF80; */
    color: white;
    border: 0px solid;
    flex-grow: 1;
    padding: 15px;
    width: 100%;
}

.n4mp-input-info-input.disabled{
    background: #295a55 0% 0% no-repeat padding-box;
   
  
    color: #FFFFFF80;
    border: 0px solid;
   
}

.n4mp-input-info-input:focus,.n4mp-input-info-input:hover, .n4mp-input-info-input:active{
    outline: none;
    border: 0px solid white;
}

/* ------------- Novo Input ------------------ */ 

.n4mp-newinput-identifier-input{
    display: flex;
    align-items: center;
    /* v1 - background: #00322d 0% 0% no-repeat padding-box; */
    background: #077b75 0% 0% no-repeat padding-box; 
    border-radius: 10px;
    opacity: 1;
    text-align: left;
    /*v1 - color: #FFFFFF80; */
    color: white;
    /*v1 - border: 1px solid transparent ; */
    border: 1px solid #077b75 ;
    /* width: 100%;
    max-width: 500px; */
}

.n4mp-newinput-identifier-input.error{
border-color: rgba(255,0,0,0.4);
}

.n4mp-newinput-identifier-input.error:hover, .n4mp-newinput-identifier-input.error:focus{
    border-color: rgba(255,0,0,0.6);
}

.n4mp-newinput-identifier-input:hover, .n4mp-newinput-identifier-input:focus, .n4mp-newinput-identifier-input:active{
    /* v1 - border: 1px solid #02B4AB; */
    border: 1px solid white;
    opacity: 1;
    outline: none;
    color: #FFFFFF;
}


.newInput, .newInput:hover, .newInput:active, .newInput:focus{
    border: 0px solid;
    border-radius: 10px;
    /* v1 - background: #00322d 0% 0% no-repeat padding-box; */
    background: #077b75 0% 0% no-repeat padding-box;
    text-align: left;
    /* color: #FFFFFF80; */
    color:white;
    outline: none;
    width: 100%;
    padding: 15px;
}

.clear-svg {
    fill: #FFFFFF80;
    width: 15px;
    height: 15px;
    cursor: pointer;
    padding: 0rem 1rem 0rem 1rem;
}

.clear-svg:hover{
    cursor: pointer;
    /* fill: #02B4AB; */
    fill:white;
}
