.inputFile {
  width: 100%;
}

.inputFile > div {
  /* border-radius: 10px;
  height: 54px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out; */
}

/* .file-donwload-link{
    font-size: small !important;
    color: black !important;
    text-decoration: none !important;
}

.inputFile-remove{
  color: rgb(189, 51, 51);
  padding-right: 10px;
}

.inputFile-remove:hover{
  color: rgb(134, 34, 34);
  cursor: pointer;
}


.inputFile label {
font-size: 0.85rem;
}

.cross{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
 .cross > label {
}

.inputFile .cross > label + label {
  
}

.inputFile .cross > label + label +label{

} */

.d-none {
  display: none !important;
}