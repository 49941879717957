
.splash-bg{
    width: 100%;
    height: 100%;
    /* background-color: #231F20; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;

}

.splash-spacer{
    flex-grow: 1;
}

.splash-logo img{
    height: 70px;
    max-height: 70px;

}

.splash-loading{
    margin-top: 40px;
    justify-content: center;
}

.splash-logo svg{
    height: 50px;
    max-height: 70px;
    margin-bottom: 15px;
}
