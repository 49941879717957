.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.body{
  font-family: 'Metropolis-Regular', "Segoe UI", Helvetica Neue, Helvetica, sans-serif;
}


/* .main-content{
    width: 100%;

    margin-right: auto;
    margin-left: auto;

    min-height: 800px;

    z-index: 2;


    background-color: rgba(255, 255, 255, 0.8);
    appearance: none;
    border-radius: 3px;
    border: 15px solid rgba(255, 255, 255, 0.9);
} */


/* @media (min-width: 576px) {
  .main-content {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .main-content {
    max-width: 720px;

  }
}

@media (min-width: 992px) {
  .main-content {
    max-width: 960px;
  }
}

@media (min-width: 1230px) {
  .main-content {
    max-width: 1170px;
  }
} */


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
