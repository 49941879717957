.header{
    display: flex;
    align-items: center;
    height: 8.125rem;
}

.header-logo{
    padding: 2.5rem 0;
    flex-grow: 1;
 }

.logo{
    width: 14.37rem;
    height: 3.125rem;
    cursor: pointer;
}

.header .header-actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    padding-right: 2.5rem;
    font: normal 1.23rem;
    color: #575757;
}

.header .header-user:hover{
    cursor: pointer;
    background: #A2A2A2  0% 0% no-repeat padding-box;
}

.n4mp-circle-avatar{
    background: #EAEAEA   0% 0% no-repeat padding-box;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.MuiList-padding { padding: 0 !important; }

.userSVG{
    display: flex;
    width: 3.12rem;
    height: 3.12rem;
}

.userSVG svg{
    height: 3.12rem;
}

.userSVG:hover #user-solid{
    display: flex;
    fill: #EAEAEA ;
    color: #EAEAEA ;
}

.dropDownMenu{ display: block; }

.header .header-burger{
    display: none;
    width: 0.93rem;
    height: 0.93rem;
    fill: #FFFFFF;
    padding: 1.25rem;
}

.header .header-burger svg{
    height: 0.93rem;
}
.header .header-burger:hover{
    cursor: pointer;
    fill: #02B4AB;
}

.header .header-burger.z-index{
    z-index: 1000;
}

.header .lockSVG{
    width: 20px;
    height: 20px;
    color: #02B4AB;
    fill: #02B4AB;
    margin-top: -4px;
}

.header .admin-option{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


/****************** OK *******************/

@media only screen and (max-width: 992px) {
    .header .header-actions{ display: none; }
    .header .header-user{ display: none; }
    .header .header-burger{
        display: flex;
        justify-content: right;
    }
}
