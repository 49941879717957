.n4mp-table-footer{
    display: flex;
    flex-direction: row;
    text-align: left;
    color: #FFFFFF;
    margin-top: 15px;
    font-weight: 300;
}

.n4mp-table-footer-area, .n4mp-table-footer-area-items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.n4mp-table-footer-separator-width{
    width: 10px;
}

/***** SELECT number per page *****/
.n4mp-table-footer-area select, .n4mp-table-footer-area-items select{
    background: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: left;
    font-weight: 500;
    
    padding: 5px 5px 5px 10px;
    margin-left: 10px;
    cursor: pointer;
}
.n4mp-table-footer-area select:focus, .n4mp-table-footer-area-items select:focus{ border-color: #02B4AB; }

.n4mp-table-footer-area select option, .n4mp-table-footer-area-items select option{ 
    background: transparent;
}

/***** PAGINATOR BUTTONS *****/
.n4mp-table-footer-pagination{
    display: flex;
    flex-direction: row;
}

.n4mp-square {
    width: 25px;
    height: 25px;
    background: #02B4AB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid white;
}

.n4mp-square:hover {
    background: #038881 0% 0% no-repeat padding-box;
}

.n4mp-square.disabled {
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    cursor: initial;
}

.n4mp-square.reversed svg {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media only screen and (max-width: 500px){
    .n4mp-table-footer{
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 15px;
    }

    .n4mp-table-footer-area{
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }


}