.action-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(255,255,255,0.05);
    border-radius: 15px;
    padding: 10px;
    fill: #FFFFFF;
    font-weight: 300;
    cursor: pointer;
}
.action-button:hover{
    /* fill: #02B4AB;
    color: #02B4AB; */
}

.action-button.blue{
    /*v1 - color: #03A2DB;
    fill: #03A2DB; */
    color: #089f97;
    fill: #089f97;
    background-color: white;
    font-weight: 400;
}

.action-button.blue:hover{
    background: transparent linear-gradient(180deg, #FFFFFFE6 0%, #e2e2e2e6 100%) 0% 0% no-repeat padding-box;
}

.action-button.disable{
    color:#FFFFFF80;
    fill: #FFFFFF80;
    background-color: #FFFFFF80;
    cursor: initial;
}

.action-button .icon{
    width: 21px;
    height: 21px;
    padding-right: 5px;
}

.action-button .text{  }

/* .disable{
    color:#FFFFFF80;
    fill: #FFFFFF80;
    cursor: initial;
} */
