.n4mp-table {
    width: 100%;
}

th{
    height: 35px;
    padding: 0 0 10px 25px;
}
th:first-child{ padding-left: 50px; }

td{
    text-align: left;
    min-width: fit-content;
    padding: 25px;
}
td:first-child{
    padding-left: 50px;
    border-left-width: 0.125rem;
    border-radius: 0.625rem 0 0 0.625rem;
}
td:last-child{
    border-right-width: 0.125rem;
    border-radius: 0 0.625rem 0.625rem 0;
}

.n4mp-table-overlay{
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.n4mp-table-overlay .MuiCircularProgress-colorPrimary {
    color: #ffffff !important;
    z-index: 100;
}


.n4mp-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}


.n4mp-table tr,
.n4mp-table td {
    opacity: 1;
}


.n4mp-table-cell-align_center {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.n4mp-table-grow {
    flex-grow: 1;
}

.n4mp-table td.n4mp-table-cell-reset {
    border: none;
}


#emptyTable{
    text-align: center;
    border: 0.125rem;
    border-radius: 0.625rem;
}



.n4mp-table-info {
    display: flex;
    justify-content: center;
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #9F9F9F;
    opacity: 1;
    padding: 20px 10px;
}
         
.svg-content{
    display: flex;
    align-items: center;
}

.svg-content .svg{
    width: 20px;
    height: 20px;
    fill: #FFFFFF80;
}
.svg-content .svg-header{
    width: 25px;
    height: 25px;
    fill: #FFFFFF;
}
.svg-content .svg:hover,
.svg-content .svg-header:hover{
    /*v1 - fill: #02B4AB; */
    fill: white;
    cursor: pointer;
}

.form-actions{
    display: flex;
    flex-direction: row;
}
    .form-actions .form-actions-grow{ flex-grow: 1; }

    .form-actions .actions{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        flex-grow: 1;
    } 