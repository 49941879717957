.molecule-submission{
    display: flex;
    flex-direction: column;
}

.molecule-submission-content{
    display: flex;
    flex-direction: row;
}

h1{
    margin: 40px 0 30px 0;
}

.vertical-separator{
    min-width: 50px;
}