.submission-form{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

    .submission-form .actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 35px;
    }

.form-actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.form-actions .form-actions-grow{ flex-grow: 1; }

.form-actions .actions{
    flex-grow: 1;
    max-width: 270px;
}

.submission-form-help{
    display: flex;
    flex-direction: row;
}

.submission-form-help-spacer{
    display: flex;
    flex-grow: 1;
}

.submission-form-help-button{
    display: flex;
    align-items: center;
    color: #03A2DB;
    cursor: pointer;
}

/* .submission-form-help-button:hover{
    text-decoration: underline;
} */

.submission-form-help-button p{
    color: #03A2DB;
    font-weight: 400;
    padding: 0.1rem;
}

.submission-form-input{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.submission-form-input-number{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    gap: 0.9375rem;
}

.counter-forms{
    display: flex;
    flex-direction: row;
}

.separator-inputs{ height: 3.125rem; }

.input-text , .input-text-up{ 
    flex-grow: 1;
    flex-basis: 0;
}

p{ 
    padding-bottom: 0.9375rem;
    padding-top: 0.9375rem;
    color: white;
    font-weight: 500;
 }

.input-number-down{
    flex-grow: 1;
}

.input-number-up{
    flex-grow: 1;
}

.error-message{
    color: brown;
    margin: 5px 0px;
}

@media only screen and (min-width: 375px) {

    .molecule-submission-content {
        flex-direction: column;
        gap: 20px;
    }
    .n4mp-newinput-identifier-input{
        flex-grow: 1;
        min-width: 205px;
        display: flex;
    }

    .separator-inputs{
        height: 5px;
    }

    .submission-form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        grid-gap: 0.9375rem;
        gap: 0.9375rem;
    }

    .submission-form-input {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    .submission-form-input-number{
        display: flex;
        flex-direction: column;
    }

    .form-actions .actions{
        flex-grow: 1;
        max-width: unset;
    }

}
@media only screen and (min-width: 670px) {
    .n4mp-newinput-identifier-input{
        flex-grow: 1;
        display: flex;
    }

    .input-costum{
        flex-grow: 1;
        min-width: 170px !important;
        display: flex;
    }

    .submission-form {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0.9375rem;
        gap: 0.9375rem;
    }

    .vertical-separator {
        min-width: 1.125rem;
        max-width: 3.125rem;
    }

    .submission-form-input{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .submission-form-input-number{
        display: flex;
        flex-direction: column;
    }

    .input-number-up{
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .input-number-down{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}


@media only screen and (min-width: 1150px) {
    .molecule-submission-content {
        flex-direction: row;
        gap: 20px;
    }

    .container-layout {
        min-width: unset;
    }

    .submission-form-input-number{
        display: flex;
        flex-direction: row;
    }

    .n4mp-newinput-identifier-input{
        display: flex;
        flex-grow: 1;
        min-width: 300px;
    }

    .input-text{
        flex-basis: 0;
        flex-grow: 1;
    }

    .input-costum{
        flex-grow: 1;
        min-width: 120px !important;
    }

    .n4mp-input-info-input{
        flex-grow: 1;
        border-right: unset !important;
    }

    .molecule-submission-content{
        flex-grow: 1;
        gap: 2%;
    }
}


@media only screen and (min-width: 1200px) {
    .form-actions .actions{
        flex-grow: 1;
        max-width: 270px;
    }
}