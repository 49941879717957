/* The container */
.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
/* Hide the browser's default checkbox */
.checkbox-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .text {
    font-size: 16px;
    padding: 0rem 1rem 0rem 1rem;
}
  
/* Create a custom checkbox */
.checkmark {
    max-width: 25px;
    max-height: 25px;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #ffffff50;
}
  
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    /*v1 - background-color: #02B4AB; */
    background: transparent linear-gradient(180deg, #FFFFFFE6 0%, #e2e2e2e6 100%) 0% 0% no-repeat padding-box;
}
  
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    /*v1 - background-color: #038881; */
    background-color: white;
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: relative;
    display: none;
}
  
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
    filter: invert(1);
}
  
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}