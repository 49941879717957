.full-screen-loader{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: none;
}

.full-screen-loader .loader{
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    z-index: 100;
}