.submissions-page{

}
.submissions-page .submissions-page-content{

}
/***************************************************/

.spacerHeight{ height: 50px; }
.spacerWidth{ width: 15px;}

.container-accordion-dashboard{
    display: none;
}

.container-tableDashboard{
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 375px){
    .container-accordion-dashboard{
        display: flex;
    }
    .container-tableDashboard{
        display: none;
    }
    .container-layout{
        flex-grow: 1;
    }
}

@media only screen and (min-width: 1200px){
    .container-accordion-dashboard{
        display: none;
    }
    .container-tableDashboard{
        display: flex;
    }
}
