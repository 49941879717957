body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}


#root{
  display: flex;
  min-height: 100vh;
}

@import "./assets/css/typography.css";


html {
  font-size: 16px;
}




/* ----------------------------
*   Pages
*  ------------------------- */
.page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page .page-content{
  width: 100%;
  max-width: 1400px;
}


/* ----------------------------
*   Main Background
*  ------------------------- */

#root {
  opacity: 1;
  height: 100vh;
  width: 100vw;
  position: relative;
}



.full{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gradient {
  background: transparent radial-gradient(ellipse at 50% 30%, #231F20 10%, #231F20 100%) 0% 0% no-repeat padding-box;

  background-image: radial-gradient(farthest-corner at 90% 40px,
    #231F20 5%, #231F20 100%);


  background: #231F20;
  background: radial-gradient(ellipse at 50% 20%, rgba(0,0,0,1) 9%, #231F20 100%, #231F20 100%, rgba(0,0,0,1) 100%);

}

.mix {
  mix-blend-mode:color;
}

.multiply {
  mix-blend-mode:overlay;
}


.overlay {
  background-color: #231F20;
  opacity: 1;
}

.overlay2 {
  background-color: #231F20;
  opacity: 0.8;
}


.overlay3 {
  background-color: #231F20;
  /* opacity: 0.1; */
}


.gradient2 {
  background: linear-gradient(to top,  #231F20, #231F20);
  opacity: 0.8;
}



.app-background{
  z-index: 0;
  position: relative;
}

.app-content {
  z-index: 100;
  display: flex;
}



.main-content{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  scrollbar-color: #245353 rgba(0, 104, 94, 0.1);
  scrollbar-width: thin;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
}

/* .main-heading{
  padding: 0 140px 140px 140px;
} */

.home-heading {
  text-align: center;
}

/* ----------------------------
*   END Main Background
*  ------------------------- */


.main-content .full-screen{
  position: relative;
}

/* .main-content .full-screen:after{
  background-color: black;
  opacity: 0.2;
} */

.overlay-sidebar{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  border-radius: 6px;
  background-color: rgba(0, 104, 94, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #245353;
}


/***** RESIZES *****/
@media only screen and (min-width: 375) {
   html {
      font-size: 10px;
    }
}

@media only screen and (min-width: 600px) {
    html {
      font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .main-content{
        padding: 0 40px 40px 40px;
    }
}

@media only screen and (min-width: 1400px) {
    .main-content{
        padding: 0 70px 70px 70px;
    }
}