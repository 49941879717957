.input-costum{
    display: flex;
    flex-direction:row;
    /*v1 - background: #00322d 0% 0% no-repeat padding-box; */
    background: #077b75 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    /* v1 - color: #FFFFFF80; */
    color: white;
    /*v1 - border: 1px solid #00322d; */
    border: 1px solid #077b75;
}

.input-costum.error{
    border-color: rgba(255,0,0,0.4);
}
    
.input-costum.error:hover, .input-costum.error:focus{
    border-color: rgba(255,0,0,0.6);
}

.input-costum:hover,  .input-costum.active{
    /*v1 - border: 1px solid #02B4AB; */
    border: 1px solid white;
    color: #FFFFFF;
    outline: none;
}

.input-costum.disabled{
    background: #295a55 0% 0% no-repeat padding-box;
    border: unset;
    cursor: unset;
}



.input-arrow-selectors{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    opacity: 1;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.selector-image-up{
    height:7px;
    width:7px;
    fill: #FFFFFF80;
}

.click-selectors{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.separator{
    height: 10px;
}

.selector-image-down{
    
    height:7px;
    width:7px;
    fill: #FFFFFF80;
    transform: rotate(180deg);
}

.values-input-number{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.input-costum.disabled .click-selectors{
    cursor: unset;
}