.header-button{
    padding: 0px 25px 0px 5px;
}


.button-text{
    font-weight: 300;
    cursor: pointer;
    color: #FFFFFF;
}
.button-text:hover{ color: #02B4AB; }



.sidebar-button{ padding: 10px; }


/* FIREFOX */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .sidebar-button .button-text:hover{
        font-weight: 600;
        color: #FFFFFF;
    }
}

/* SAFARI */
.safari .sidebar-button .button-text:hover{
    font-weight: 600;
    color: #FFFFFF;
}