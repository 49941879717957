.accordion-row{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    flex-grow: 1;
    border-radius: 15px;
    background-color: #077b75;
} 

.accordion-row:after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius: 15px;
    z-index: -10;
}

.accordion-separator-height{
    height: 15px;
}

.accordion-row-close{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.accordion-title{ font-weight: 400; }

.accordion-empty{ justify-content: center; }

.accordion-svg-spacer{ width: 20px; }

.accordion-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.accordion-svg{
    fill: #FFFFFF80;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.accordion-svg:hover{
    fill: white;
}

.accordion-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    padding: 10px 20px;
    text-align: right;
}

.accordion-info-text{
    font-weight: 300;
    text-align: left;
}

.accordion-row-open{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.accordion-row-open .accordion-info:nth-child(odd){
    background: transparent;
    background-color: #089f97;
    position: relative;
    border-radius: 15px;
}

.accordion-row-open .accordion-info:nth-child(odd):after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: black;
    opacity: 0.1;
    border-radius: 15px;
    z-index: -10;
}


.fileName-hide{
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}

/* .fileName-hide{
    display: none;
} */

/* .accordion-info-excel:hover + .fileName-hide {
    color: #FFFFFF;
    font-weight: 300;
    display: block;

} */



/* .svg-content-accordion{
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 10px;
}

.svg-content-accordion .svg-header{
    width: 25px;
    height: 25px;
    fill: #FFFFFF;
} */