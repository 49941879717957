.popup-main{
    /* max-width: 500px;
    max-height: 450px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popup-main .popup-header{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.popup-main .popup-close{
    display: flex;
    margin-left: auto;
    cursor: pointer;
    fill: white;
}

.popup-main .popup-svg{
    width: 30px;
    height: 30px;
}

.popup-main .popup-close:hover{
    /* background-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px; */
    fill: #000000;
}

.popup-main .popup-title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #FFFFFF;
    flex-grow: 1;
    font-weight: 300;
}

.popup-main .batchId{
    color: #FFFFFF;
    font-weight: 400;
}

.popup-main .popup-message{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* max-height: 40vh;
    overflow-y: scroll; */
}

.popup-main .popup-message-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 400;
}

.popup-main .popup-message .color-light p{ font-weight: 200; }

.popup-main .popup-align-left{
    display: flex;
    justify-content: left;
    width: 100%
}



.popup-main .popup-message-large{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-color: #245353 rgba(0, 104, 94, 0.1);
    scrollbar-width: thin;
    max-width: 800px;
    max-height: 400px;
    padding-right: 15px;
}

.popup-message-large-note{
    color: black !important;
    font-weight: 600 !important;
}

    .popup-message-large p{
        padding-bottom: 0rem !important;
        color: white;
    }

    .popup-message-large li{
        color: white;
        font-weight: 300;
    }

    .popup-message-large b{
        color: white !important;
        font-size: 18px;
    }

    .popup-message-large span{
        color: white;
        font-weight: 500;
    }

    .popup-message-large ul{
        margin-block-start: 1rem !important;
        margin-block-end: 1rem !important;
    }

    .popup-message-large a{
        color: black;

        /*to break anchor tag with long url*/
        overflow-wrap: break-word;
        word-wrap: break-word;
    
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    .popup-message-large a:active{ color: #037199; }



.popup-main .popup-separator-height{ height: 30px; }

.popup-main .popup-actions{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.popup-main .popup-actions .popup-separator{
    width: 30px;
    height: 0;
}

.status-error{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    .status-error .error-line{
        display: flex;
        flex-direction: row;
    }
    .status-error .error-line .red{
        /* color: #A52A2A; */
        color:#fe9200;
        font-weight: 600;
    }
    .status-error .error-line .yellow{
        color: yellow;
        font-weight: 500;
    }
    .status-error .error-line .white{
        color: #FFFFFF;
        font-weight: 500;
    }
    .status-error .subtitle{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px;
    }
    .status-error .subtitle .line-red{
        /* background-color: #A52A2A; */
        background-color: #ff9300;
        width: 20px;
        height: 5px;
    }
    .status-error .subtitle .line-yellow{
        background-color: yellow;
        width: 20px;
        height: 5px;
    }

.popup-alert{
    /* color: #A52A2A; */
    color: #ff9300;
    font-weight: 600;
}



@media only screen and (min-width: 375px){
    .popup-main .popup-actions{
        flex-direction: column;
    }

    .popup-main .popup-actions .popup-separator{
        width: 0;
        height: 30px;
    }
}

@media only screen and (min-width: 600px){
    .popup-main .popup-actions{
        flex-direction: row;
    }

    .popup-main .popup-actions .popup-separator{
        width: 30px;
        height: 0px;
    }
}