@import "@fontsource/exo/variable.css";


body {
    font-family: "ExoVariable", "Exo", "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    font-size: 16px;
    color: #FFFFFF;
}

h1 {
    font-weight: 300;
    font-size: 1.563rem;
}


h2 {
    font-weight: 200;
    font-size: 1.25rem;
}


p {
    font-weight: 100;
    color: #FFFFFF80;
}




.home-heading {
    font-weight: 600;
    font-size: 4.375rem;
}

.home-heading span {
    color: #02B4AB;
}


input::placeholder {
    font-family: "ExoVariable", "Exo", "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:#FFFFFF80;
}
