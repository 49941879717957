.popup-main{
    display: flex;
    justify-content: center;
}

.result-popup{
    display: flex;
    flex-direction: column;
    min-width: 240px;
    width: fit-content;
    min-height: 200px;
    position: relative;
    z-index: 100;
    padding: 50px;
    border-radius: 15px;
    background: transparent;
    backdrop-filter: blur(15px);
    /* -webkit-backdrop-filter: blur(15px);   */
    overflow-y: auto;
    /* background-image: linear-gradient(-45deg, #02B4AB , rgba(0, 0, 0, 0.9)); */
    background-color: #077B75;
}

.result-popup:after{
    opacity: 0.5;
    z-index: -10;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
}

.result-popup-header{
    display: flex;
    flex-direction: row;
}

.result-popup-header-title{
    flex-grow: 1;
    font-weight: 500;
}

.result-popup-header-actions{
    width: 25px;
    height: 25px;
    fill: white;
    cursor: pointer;
    padding-left: 15px;
}
.result-popup-header-actions:hover{
    fill: #000000;
}


.popup-separator{ border-bottom: 1px solid #FFFFFF80; }

.popup-separatorHeight{ height: 15px; }

.result-popup .popup-message{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 40vh;
    overflow-y: scroll;
}

.popup-message .popup-drugName{
    display: flex;
    /* overflow-x: hidden;
    text-overflow: ellipsis; */
    padding: 10px 10px 10px 15px;
}

/* @media only screen and (max-width: 599px){
    .result-popup-header-title{
        font-size: 0.7rem;
    }

    .result-popup-header-actions{
        min-width: 20px;
        max-width: 25px;
    }

    .popup-drugName{ padding: 5px 5px 5px 15px; }

    .result-popup{
        padding: 10px;
    }
} */

@media only screen and (min-width: 600px){
    .result-popup-header-title{
        font-size: 16px;
    }
}