.combo-container{
    position: relative;
    /*v1 - background-color: #00322d; */
    background-color: #089f97;
}
.combo{
    display: flex;
    flex-direction: column;
}

.combo-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.open{
    /*v1 - border-color: #02B4AB; */
    border-color: #077b75;
}

.combo-group.disable{
    border: 1px solid;
    border-color: #FFFFFF80;
    color: #FFFFFF80;
    cursor: initial;
}

.combo-group:focus-within{ border-color: #02B4AB; }

.combo-group .combo-text{
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 5px;
}
.combo-group .combo-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #FFFFFF;
    width: 20px;
    height: 20px;
    padding: 5px;
}

.combo-group .combo-icon svg{
    height: 20px;
}

.combo-group .combo-icon.disable{
    fill: #FFFFFF80;
}

.combo-dropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: transparent;
    width: 100%;
    border-radius: 5px;
    /* border: 1px solid #02B4AB; */
    border: 1px solid #077b75;
    /*v1 - background-color: #00322d; */
    background-color: #089f97;
    box-sizing: border-box;
}

.combo-dropdown .combo-option{
    padding: 5px;
    display: flex;
    align-items: center;
}
.combo-dropdown .combo-option:hover{
    /*v1 - background-color: #02B4AB; */
    background-color: #077b75;
    cursor: pointer;
}