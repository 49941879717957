.molecules-list{
    display: flex;
    flex-direction: column;
}
    .molecules-list .title{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
        .molecules-list .title .total{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #02B4AB;
            width: 20px;
            height: 20px;
            font-size: 10px;
            font-weight: 300;
        }

    .molecules-list .actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 35px;
    }

    
    .molecules-list .separator-width{ width: 10px; }


.container-accordion-list{ display: none; }


.container-tableList{
    display: flex;
    flex-direction: column;
}



@media only screen and (min-width: 375px){
    .container-accordion-list{
        display: flex;
    }
    .container-tableList{
        display: none;
    }
    .container-layout{
        flex-grow: 1;
    }

    .molecules-list .actions{ padding-bottom: 20px; }
}



@media only screen and (min-width: 1200px){
    .container-accordion-list{  display: none; }
    
    .container-tableList{ display: flex; }

    .svg-content-accordion{ display: none; }

    .molecules-list .actions{ padding-bottom: 10px; }
}
