.btn-submit-container button{
    appearance: none;
    outline: 0;
    border: 0px solid rgba(255, 255, 255, 1);
    border-radius: 10px;
    background-color: rgba(50, 48, 49, 0.8);
    padding: 0.3rem;
    display: block;
    text-align: center;
    font-size: 16px;
    font-family: "ExoVariable", "Exo", "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    color: white;
    transition-duration: 0.25s;
    width: 100%;
    cursor: pointer;
}

.btn-submit-container button:hover{
    background-color:  rgba(50, 48, 49, 0.4);
}

.btn-submit-container{
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: center;
}

.btn-submit-container button .btn-blue{  
    background: transparent linear-gradient(180deg, #03A2DB 0%, #0378A2 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF; 
}
.btn-submit-container button .btn-blue:hover{
    background: transparent linear-gradient(180deg, #03A2DBE6 0%, #0378A2E6 100%) 0% 0% no-repeat padding-box;
}

.btn-submit-container button .btn-green{
    background: transparent linear-gradient(180deg, #02B4AB 0%, #038881 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF; 
}
.btn-submit-container button .btn-green:hover{
    background: transparent linear-gradient(180deg, #02B4ABE6 0%, #038881E6 100%) 0% 0% no-repeat padding-box;
}

.btn-submit-container button .btn-green-disable{
    background: #FFFFFF80;
}

.btn-submit-container button .btn-white{
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #BCBCBC 100%) 0% 0% no-repeat padding-box;
    color: #231F20; 
}
.btn-submit-container button .btn-white:hover{
    background: transparent linear-gradient(180deg, #FFFFFFE6 0%, #BCBCBCE6 100%) 0% 0% no-repeat padding-box;
}