.errorPage-content{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.error-heading {
    text-align: center;
    font-size: 200px;
    color: hsla(0, 0%, 100%, 0.2);
    font-weight: 600;
}

.error-subtitle{
    text-align: center;
    font-size: 35px;
    color: #02B4AB;
    font-weight: 600;
}

.error-res{
    text-align: center;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF80;
}

.error-height{ height: 60px; }

.error-actions {
    display: flex;
    flex-direction: row;
    width: 10%;
}

@media only screen and (min-width: 375px) {
    .error-actions {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .error-heading{
        font-size: 130px;
        font-weight: 600;
    }
    .error-subtitle{
        font-size: 25px;
        font-weight: 600;
    }
    .error-res{
        font-size: 16px;
        font-weight: 300;
    }
}

@media only screen and (min-width: 600px) {
    .error-actions {
        display: flex;
        flex-direction: row;
        width: 60%;
    }

    .error-heading{
        font-size: 180px;
        font-weight: 600;
    }
    .error-subtitle{
        font-size: 35px;
        font-weight: 600;
    }
    .error-res{
        font-size: 20px;
        font-weight: 300;
    }
}
@media only screen and (min-width: 1150px) {
    .error-actions{
        display: flex;
        flex-direction: row;
        width: 200px;
        gap:20px;
    }

    .error-heading{
        font-size: 200px;
        font-weight: 600;
    }
}
