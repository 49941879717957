
    .container-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px;
        min-width: fit-content;
        position:relative;
        border-radius: 15px;
        background-color:#089f97;
    }

    .container-layout:after {
        content: "";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: white;
        opacity: 0.05;
        border-radius: 15px;
        z-index: -10;
    }

  /* @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .container-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px;
        min-width: fit-content;
        background: transparent;
        position:relative;
        border-radius: 15px;
        background-color:#089f97;
    }

    .container-layout:after {
        content: "";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: white;
        opacity: 0.9;
        border-radius: 15px;
        z-index: -10;
        background: rgb(12, 61, 57, 1);
    }
  } */
  
.flexgrow{ flex-grow: 1; }

.container-layout.popup-layout{
    padding: 50px;
    /* background: #2440408e !important; */
    border: solid black 1px;
}
