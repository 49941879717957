.iconbutton{
    border-radius: 10px;
    height: 54px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.btn-icon{
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0.3125rem;
}

.btn-text{ }

    /* SVG */
    .btn-icon{ fill:#231F20; }
    
   

.iconbutton-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}

.btn-blue{  
    background: transparent linear-gradient(180deg, #03A2DB 0%, #0378A2 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF; 
}
.btn-blue:hover{
    background: transparent linear-gradient(180deg, #03A2DBE6 0%, #0378A2E6 100%) 0% 0% no-repeat padding-box;
}

.btn-red{  
    background: transparent linear-gradient(180deg, #951233 0%, #951233 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF; 
}
.btn-red:hover{
    background: transparent linear-gradient(180deg, #951233E6 0%, #951233E6 100%) 0% 0% no-repeat padding-box;
}

.btn-dark{  
    background: transparent linear-gradient(180deg, #231f20 0%, #231f20 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF; 
}
.btn-dark:hover{
    background: transparent linear-gradient(180deg, #231f20E6 0%, #231f20E6 100%) 0% 0% no-repeat padding-box;
}

.btn-green{
    background: transparent linear-gradient(180deg, #02B4AB 0%, #038881 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF; 
}
.btn-green:hover{
    background: transparent linear-gradient(180deg, #02B4ABE6 0%, #038881E6 100%) 0% 0% no-repeat padding-box;
}

.btn-white{
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #BCBCBC 100%) 0% 0% no-repeat padding-box;
    color: #231F20; 
}
.btn-white:hover{
    background: transparent linear-gradient(180deg, #FFFFFFE6 0%, #BCBCBCE6 100%) 0% 0% no-repeat padding-box;
}

.btn-blue.disable,
.btn-green.disable,
.btn-white.disable{
    color:#FFFFFF80;
    background: #FFFFFF80;
    cursor: initial;
}
/* .disable:hover{
    background: #FFFFFF80;
} */
/****************** OK *******************/