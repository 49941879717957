@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .sidebar-content{
        background: #8b7878;
        opacity: 1;
        height: 100%;
        max-width: 280px;
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: transparent;
        backdrop-filter: blur(25px);
        -webkit-backdrop-filter: none;
        /* -webkit-backdrop-filter: blur(35px); */
        box-sizing: border-box;
    }

    .safari .sidebar-content{
        opacity: 1;
        height: 100%;
        max-width: 280px;
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #077B75;
        box-sizing: border-box;
    }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .sidebar-content{
        background: #8b7878;
        opacity: 1;
        height: 100%;
        max-width: 280px;
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #077B75;
        box-sizing: border-box;
    }
}

.sidebar-content:after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgb(53, 51, 51);
    opacity: 0.15;
    z-index: -10;
}



.sidebar-user-header{
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
}

    .sidebar-user-header .sidebar-user-header-svg{
        display: flex;
        align-items: center;
        background: #EAEAEA  0% 0% no-repeat padding-box;
        border-radius: 50%;
        min-width: 35px;
        min-height: 35px;
        overflow: hidden;
    }



.sidebar-user-info{
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

    .sidebar-user-info .sidebar-user-name{
        font: normal 16px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .sidebar-user-info .sidebar-user-email{
        font-size: 14px;
        color:#02B4AB;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .safari .sidebar-user-info .sidebar-user-email{
        color: #19c9c0 !important;
    }

    .firefox .sidebar-user-info .sidebar-user-email{
        color: #19c9c0 !important;
    }


.sidebar-separator-user{
    border-bottom: solid 1px #FFFFFF26;
    width: 100%;
}

.sidebar-spacer{ height: 30px; }
.sidebar-spacer-actions{ height: 20px; }
.sidebar-spacer-cards{ height: 20px; }



.sidebar-actions .admin-option{
 display: flex;
 flex-direction: row;
 cursor: pointer;
    align-items: center;
}

.sidebar-actions .lockSVG-sidebar{
    width: 20px;
    height: 20px;
    color: #02B4AB;
    fill: #02B4AB;
    margin-top: -4px;
}

.sidebar-options-info{
    display: flex;
    flex-direction: row;
    padding: 10px;
    color: #FFFFFF;
    fill: #FFFFFF;
}

    .sidebar-options-info .option-icon-sidebar{
        width: 18px;
        height: 18px;
    }
    .sidebar-options-info .option-text-sidebar{
        font-weight: 300;
        text-align: left;
        margin-left: 15px;
    }

.sidebar-options-info:hover{
    color: #02B4AB;
    fill: #02B4AB;
    cursor: pointer;
}


.sidebar-user-header .separator-width{
    width: 10px;
}


@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .sidebar-options-info .option-text-sidebar:hover{
        color: #FFFFFF;
        cursor: pointer;
        font-weight: 600;
    }
    .sidebar-options-info:hover{
        color: #FFFFFF;
        fill: #FFFFFF;
    }
}

.safari .sidebar-options-info .option-text-sidebar:hover{
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 600;
}
.safari .sidebar-options-info:hover{
    color: #FFFFFF;
    fill: #FFFFFF;
}
