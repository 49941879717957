.full-screen-panel{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .content-user{
        padding: 20px;
        position: absolute;
        top: 100px;
        right: 70px;
        border-radius: 15px;
        max-width: 350px;
        z-index: 100;    
        background: #8b7878;
        opacity: 1;
        background: transparent;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: none;
        box-sizing: border-box;
    }
    .options-info:hover{
        color: #02B4AB;
        fill: #02B4AB;
    }

    .safari .content-user{
        padding: 20px;
        position: absolute;
        top: 100px;
        right: 70px;
        border-radius: 15px;
        max-width: 350px;
        z-index: 100;
        background-color: #077B75;
        opacity: 1;
        box-sizing: border-box;
    }

    .safari .options-info:hover{
        color: #FFFFFF;
        fill: #FFFFFF;
        font-weight: 600;
    }


}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .content-user{
        padding: 20px;
        position: absolute;
        top: 100px;
        right: 70px;
        border-radius: 15px;
        max-width: 350px;
        z-index: 100;
        background: #077B75;
        opacity: 1;
        box-sizing: border-box;
    }

    .options-info:hover{
        color: #FFFFFF;
        fill: #FFFFFF;
        font-weight: 600;
    }
  
}

@media only screen and (min-width: 375) {
    .content-user{
        right: 40px;
    }
 }
 
 @media only screen and (min-width: 600px) {
    .content-user{
        right: 40px;
    }
 }
 
 @media only screen and (min-width: 768px) {
    .content-user{
        right: 50px;
    }
 }
 
 @media only screen and (min-width: 1400px) {
    .content-user{
        right: 70px;
    }
 }

.content-user::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: 15px;
    opacity: 0.15;
    z-index: -10;
}

.user-header{
    display: flex;
    flex-direction: row;
}
    .user-header .user-header-svg{
        display: flex;
        align-items: center;
        background: #EAEAEA  0% 0% no-repeat padding-box;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    .user-header .user-header-svg svg{
        height: 40px;
    }

.user-info{
    margin-left: 15px;
    align-items: center;
}
    .user-info .user-name{
        font: normal 16px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .user-info .user-email{
        font: normal 14px;
        color: #02B4AB;
        font-weight: 600;
    }

    .safari .user-info .user-email{
        color: #19c9c0 !important;
    }
    
    .firefox .user-info .user-email{
        color: #19c9c0 !important;
    }

.separator-user{
    border-bottom: solid 1px #FFFFFF26;
    margin-top: 10px;
    width: 100%;
}

.options-info{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    padding: 10px;
    cursor: pointer;
    color: #FFFFFF;
    fill: #FFFFFF;
    font-weight: 300;
}
    .options-info .option-icon{
        width: 18px;
        height: 18px;
    }

    .options-info .option-text{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
        text-align: left;
        font: normal normal 600 16px;
        margin-left: 15px;
    }
