.landing-content{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  /* .landing-header-logos{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .landing-header-logo1{
    padding: 2rem;  
    align-items: center;
    width: 250px;
    height: 60px;
  }

  .landing-header-logo2{
    width: 280px;
    height: 70px;
    align-items: center;
    object-fit: fill;
    border-left: 1px solid white;
    border-radius: 2px;
  } */

  .landing-header{

  }

 .landing-heading {
    text-align: center;
    font-size: 80px;
    font-weight: 600;
  }


  .landing-heading span {
    color: #02B4AB;
  }


  .landing-actions{
    display: flex;
    flex-direction: row;
    gap:50px;
  }



  #test {
    margin-top: 25px;
    font-size: 21px;
    text-align: center;


    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 3s; /* Firefox < 16 */
        -ms-animation: fadein 3s; /* Internet Explorer */
         -o-animation: fadein 3s; /* Opera < 12.1 */
            animation: fadein 3s;
}

@keyframes fadein {
  from { opacity: 0;  transform: translateY(30%); }
  to   { opacity: 1;  transform: translateY(0%); }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0;  transform: translateY(30%); }
  to   { opacity: 1;  transform: translateY(0%); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0;  transform: translateY(30%); }
  to   { opacity: 1;  transform: translateY(0%); }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0;  transform: translateY(30%); }
  to   { opacity: 1;  transform: translateY(0%); }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0;  transform: translateY(30%); }
  to   { opacity: 1;  transform: translateY(0%); }
}


@media only screen and (min-width: 375px) {

  .landing-header{
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
  }

  .landing-heading{
    text-align: center;
    font-size: 50px;
    font-weight: 600;
  }

  .landing-actions{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:20px;
  }

  .landing-header-logos{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing-header-logo1{
    align-items: center;
    width: 250px;
    height: 60px;
  }

  .landing-header-logo2{
    width: 280px;
    height: 70px;
    align-items: center;
    object-fit: fill;
  }

  .landing-header-division{
    border-top: 1px solid white;
    border-radius: 2px;
    width: 200px;
    height: 10px;
    margin: 2rem 0rem 1rem 0rem;
  }

}

@media only screen and (min-width: 600px) {
  .landing-header{
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
  }

  .landing-heading{
    text-align: center;
    font-size: 70px;
    font-weight: 600;
  }

  .landing-actions{
    display: flex;
    flex-direction: column;
    width: 80%;
    gap:20px;
  }

  .landing-header-logos{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .landing-header-logo1{
    padding: 3rem;  
    align-items: center;
    width: 250px;
    height: 60px;
  }

  .landing-header-logo2{
    width: 280px;
    height: 70px;
    align-items: center;
    object-fit: fill;
    border-radius: 2px;
  }

  .landing-header-division{
    border-top: none;
    height: 60%;
    width: 2px;
    background-color: white;
  }
}

@media only screen and (min-width: 1150px) {
  .landing-actions{
    display: flex;
    flex-direction: row;
    width: 20%;
    gap:20px;
  }

  .landing-heading{
    text-align: center;
    font-size: 70px;
    font-weight: 600;
  }

  .landing-header{
    display: flex;
    width: unset;
    justify-content: center;
    align-items: center;
  }

  .landing-header-logos{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .landing-header-logo1{
    padding: 3rem;  
    align-items: center;
    width: 250px;
    height: 60px;
  }

  .landing-header-logo2{
    width: 280px;
    height: 70px;
    align-items: center;
    object-fit: fill;
    border-radius: 2px;
  }

  .landing-header-division{
    width: 2px;
    height: 60%;
    background-color: white;
  }
}
