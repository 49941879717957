.users-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container-search{
    display: flex;
    align-items: center;
}

.search-svg{
    fill: #FFFFFF80;
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 0rem 1rem 0rem 1rem;
}

.search-svg:hover{
    cursor: pointer;
    /*v1 - fill: #02B4AB; */
    fill: white;
}

.container-accordion-list{
    display: none;
}

.container-tableList{
    display: flex;
    flex-direction: column;
}


@media only screen and (min-width: 375px){
    .container-accordion-list{
        display: flex;
    }
    .container-tableList{
        display: none;
    }
    .container-layout{
        flex-grow: 1;
    }
}

@media only screen and (min-width: 1200px){
    .container-accordion-list{
        display: none;
    }
    .container-tableList{
        display: flex;
    }
}
